const fetchWrapper = async (resource, options = {}) => {
  if (!resource) return;

  const headers = new Headers(options.headers || {});

  if (typeof window === 'object'
    && window.store
    && window.store.getState
  ) {
    const state = window.store.getState();

    if (state && state.user && state.user.token) {
      const { token } = state.user;

      headers.append('Authorization', `Bearer ${token}`)
    }
  }

  return fetch(resource, {
    ...options,
    headers,
  });
};

export default fetchWrapper;
